const PRICING_TABLE = [
  { min: 0, max: 3, price: 0 },
  { min: 4, max: 10, price: 3.5 },
  { min: 11, max: 20, price: 2.5 },
  { min: 21, max: 30, price: 1.5 },
  { min: 31, max: null, price: 1 },
];

export const calculateNextGraduatePrice = (numClients: number) => {
  // Calculate the next price
  const numClientsPlusOne = numClients + 1;
  // Calculate the next price based on the current number of clients
  const price = PRICING_TABLE.reduce((acc, bracket) => {
    if (
      numClientsPlusOne >= bracket.min &&
      (bracket.max === null || numClientsPlusOne <= bracket.max)
    ) {
      return bracket.price;
    }
    return acc;
  }, 0);

  return price.toFixed(2);
};

export const calculateGraduateTotalPrice = (numClients: number) => {
  // Calculate the total price based on the current number of clients using graduated pricing
  const price = PRICING_TABLE.reduce((acc, bracket) => {
    // If the number of clients is greater than the min of the top bracket
    if (numClients >= bracket.min && bracket.max === null) {
      return acc + (numClients - bracket.min + 1) * bracket.price;
    }
    // If the number of clients is greater than the max of the current bracket, then add the full price of the bracket
    else if (bracket.max && numClients > bracket.max) {
      return acc + (bracket.max - bracket.min + 1) * bracket.price;
    }
    // If the number of clients is less than the max of the current bracket, then add the price of the clients in the bracket
    else if (bracket.max && numClients <= bracket.max) {
      return acc + (numClients - bracket.min) * bracket.price;
    }
    return acc;
  }, 0);

  // two decimal places
  return price.toFixed(2);
};

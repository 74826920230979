import { ReactNode, createContext, useState } from 'react';
// mui
import { Dialog, Button, Stack, Typography, Link } from '@mui/material';
// components
import { CheckoutForm } from 'src/components/stripe';
import { calculateNextGraduatePrice } from 'src/utils/calculateGraduatePricing';
import useCurrentUser from 'src/hooks/useCurrentUser';

// ----------------------------------------------------------------------

export enum STRIPE_MODAL_TYPES {
  ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD',
  ADD_NEW_CLIENT = 'ADD_NEW_CLIENT',
  REMOVE_CLIENT = 'REMOVE_CLIENT',
}

export type StripeModalDetails = {
  type: STRIPE_MODAL_TYPES;
  name?: string;
  onConfirm?: () => Promise<void>;
};
export type StripeModalContextProps = {
  openStripeModal: ({
    type,
    name,
    onConfirm,
  }: {
    type: STRIPE_MODAL_TYPES;
    name?: string;
    // async function to run after confirming
    onConfirm?: () => Promise<void>;
  }) => void;
  closeStripeModal: () => void;
};

const initialState: StripeModalContextProps = {
  openStripeModal: () => {},
  closeStripeModal: () => {},
};

const StripeModalContext = createContext(initialState);

type StripeModalProviderProps = {
  children: ReactNode;
};

function StripeModalProvider({ children }: StripeModalProviderProps) {
  const [open, setOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState<StripeModalDetails | null>(null);

  const openStripeModal = ({
    type,
    name,
    onConfirm,
  }: {
    type: STRIPE_MODAL_TYPES;
    name?: string;
    onConfirm?: () => Promise<void>;
  }) => {
    setModalDetails({ type, name, onConfirm });
    setOpen(true);
  };

  const closeStripeModal = () => {
    setOpen(false);
    setModalDetails(null);
  };

  // const hasSubscription = true;

  return (
    <StripeModalContext.Provider
      value={{
        openStripeModal,
        closeStripeModal,
      }}
    >
      {children}
      {modalDetails && (
        <StripeModal modalDetails={modalDetails} open={open} onClose={closeStripeModal} />
      )}
    </StripeModalContext.Provider>
  );
}

export { StripeModalProvider, StripeModalContext };

// ----------------------------------------------------------------------

type ModalProps = {
  name?: string;
  onConfirm?: () => Promise<void>;
  open: boolean;
  onClose: () => void;
};

const NoPaymentDetailsStripeModal = ({ name, onConfirm, open, onClose }: ModalProps) => (
  <Dialog open={open} onClose={onClose}>
    <Stack spacing={2} sx={{ p: 4 }} textAlign="center">
      <Stack sx={{ pb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Client limit reached
        </Typography>
        {name ? (
          <Typography variant="body2">
            To add <b>{name}</b> as a client, sign up to our flexi plan
          </Typography>
        ) : (
          <Typography variant="body2">Sign up to our flexi plan to add more clients</Typography>
        )}
        <Link
          href="https://www.oniworkout.co.nz/pricing"
          target="_blank"
          variant="caption"
          sx={{ cursor: 'pointer' }}
        >
          View pricing information
        </Link>
      </Stack>

      <CheckoutForm onConfirm={onConfirm} />

      <Button onClick={onClose}>Cancel</Button>
    </Stack>
  </Dialog>
);

// ----------------------------------------------------------------------

const AddClientStripeModal = ({ name, onConfirm, open, onClose }: ModalProps) => {
  const { numberOfActiveClients } = useCurrentUser();
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack spacing={2} sx={{ p: 4 }} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Reactivate client
        </Typography>

        <Stack sx={{ pb: 2 }}>
          {name ? (
            <Typography variant="body2">
              Make <b>{name}</b> an active client?
            </Typography>
          ) : (
            <Typography variant="body2">Make this client active?</Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            <b>${calculateNextGraduatePrice(numberOfActiveClients)}</b> usd will be added to this
            months invoice.
          </Typography>
          <Link
            href="https://www.oniworkout.co.nz/pricing"
            target="_blank"
            variant="caption"
            sx={{ cursor: 'pointer' }}
          >
            View pricing information
          </Link>
        </Stack>

        {/* <Stack sx={{ pt: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Would you like to continue?
        </Typography>
      </Stack> */}

        <Stack minWidth={300} spacing={2}>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Yes
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

// ----------------------------------------------------------------------

const RemoveClientStripeModal = ({ name, onConfirm, open, onClose }: ModalProps) => (
  <Dialog open={open} onClose={onClose}>
    <Stack spacing={2} sx={{ p: 4 }} textAlign="center">
      <Typography variant="h4" gutterBottom>
        Mark client as inactive
      </Typography>

      <Stack sx={{ pb: 2 }}>
        {name ? (
          <Typography variant="body2">
            Are you sure you want to remove <b>{name}</b> as a client?
          </Typography>
        ) : (
          <Typography variant="body2">Are you sure you want to remove this client?</Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          This client will be marked as inactive at the end of the current billing cycle.
        </Typography>
        <Link variant="caption" sx={{ cursor: 'pointer' }}>
          Learn more
        </Link>
      </Stack>

      {/* <Stack sx={{ pt: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Would you like to continue?
        </Typography>
      </Stack> */}

      <Stack minWidth={300} spacing={2}>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Yes
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </Stack>
    </Stack>
  </Dialog>
);

// ----------------------------------------------------------------------

export type StripeModalProps = ModalProps & {
  modalDetails: StripeModalDetails;
};

const StripeModal = ({ open, onClose, modalDetails }: StripeModalProps) => {
  const { type, name, onConfirm } = modalDetails;
  switch (type) {
    case STRIPE_MODAL_TYPES.ADD_PAYMENT_METHOD:
      return (
        <NoPaymentDetailsStripeModal
          name={name}
          onConfirm={onConfirm}
          open={open}
          onClose={onClose}
        />
      );
    // return hasSubscription ? <ExistingSubscriptionStripeModal /> : <NewSubscriptionStripeModal />;
    case STRIPE_MODAL_TYPES.ADD_NEW_CLIENT:
      return (
        <AddClientStripeModal name={name} onConfirm={onConfirm} open={open} onClose={onClose} />
      );
    case STRIPE_MODAL_TYPES.REMOVE_CLIENT:
      return (
        <RemoveClientStripeModal name={name} onConfirm={onConfirm} open={open} onClose={onClose} />
      );
    default:
      return null;
  }
};

// ----------------------------------------------------------------------
